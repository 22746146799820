import { ListButton, RefreshButton, Show, TagField, useModal, useSelect } from "@refinedev/antd";
import { IResourceComponentsProps, useGetIdentity, useParsed, useShow, useTranslate, useApiUrl, useCustomMutation } from "@refinedev/core";
import { Button, Card, Col, Divider, Form, Input, Modal, Row, Select, Spin, Switch, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { DateField } from "components/fields/date";
import { ErrorComponent } from "components/page/error";
import IAction from "entities/event/IAction";
import IIncident from "entities/event/IIncident";
import { IUserForView } from "entities/visa/IUserForView";
import { getAllMessages } from "helpers/serviceResponseHelper";
import { ConvertBoolean, ConvertBooleanToColor, ConvertIncidentStatusToColor, ConvertIncidentStatusToText } from "helpers/statusHelper";
import { DataType, OptionType } from "interfaces/IResourceComponentType";
import { useGetIdentityInterface } from "models/useGetIdentityInterface";
import { useState } from "react";




const { Title, Text } = Typography;

export const IncidentShow: React.FC<IResourceComponentsProps<DataType, OptionType>> = (props) => {
    const translate = useTranslate();
    const API_URL = useApiUrl();

    const { show: redirectModalShow, close: redirectModelClose, modalProps: redirectModalProps } = useModal();
    const [redirectForm] = Form.useForm();

    const { show: answerModalShow, close: answerModalClose, modalProps: answerModalProps } = useModal();
    const [answerForm] = Form.useForm();

    const { selectProps: userSelectProps } = useSelect<IUserForView>({
        resource: "User",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Username",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { data: identity } = useGetIdentity<useGetIdentityInterface>();
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();
    const { queryResult: incidentQueryResult } = useShow<IIncident>();
    const { data, isLoading } = incidentQueryResult;
    const record = data?.data;
    console.log(record)

    let columns: ColumnsType<IAction> = [
        {
            dataIndex: "dateCreated",
            title: translate("pages.action.fields.dateCreated"),
            render(value, record, index) {
                return <DateField value={value} format="LL" />
                console.log(value)
            },
        },
        {
            dataIndex: ["user", "label"],
            title: translate("pages.action.fields.user")
        },
        {
            dataIndex: "isRedirectAction",
            title: translate("pages.action.fields.isRedirectAction"),
            render(value, record, index) {
                return (
                    <TagField value={ConvertBoolean(value)} color={ConvertBooleanToColor(value)} />
                )
            },
        },
        {
            dataIndex: "precaution",
            title: translate("pages.action.fields.precaution")
        }
    ]

    const descRate = ['Düşük', 'Normal', 'Acil'];

    const canRunAction = record?.incidentStatusID != 0 && record?.incidentStatusID != 4
    const isAssignedMe = record?.responsibleUserID == (identity?.userID ?? 0);
    const isMyIncident = record?.ownerUserID == (identity?.userID ?? 0);

    const amIAuthorized: boolean = (isAssignedMe || isMyIncident || (identity?.isLocalAdmin ?? false) || (identity?.isGlobalAdmin ?? false)) ? true : false;

    const closeIncidentUrl = `${API_URL}/Incident/CloseIncident`;
    const redirectIncidentUrl = `${API_URL}/Incident/AssignIncident`;
    const answerIncidentUrl = `${API_URL}/Incident/AddResolveIncident`;

    const { mutate, isLoading: mutateLoading } = useCustomMutation({
        mutationOptions: {
            retry: 1
        }
    });

    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleConfirmation = () => {
        setShowConfirmation(true);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const handleConfirm = () => {
        setShowConfirmation(false);
        closeIncident();
    };

    const closeIncident = () => {
        mutate({
            url: closeIncidentUrl,
            method: "post",
            values: {
                incidentID: parsedId
            },
            errorNotification: (data: any, values: any, resource: any) => {
                var messages = getAllMessages(data.response.data).error;

                var message = `Something went wrong when changing state ${data.id}`;

                if (messages && messages.length > 0) message = messages[0];

                return {
                    message: message,
                    description: translate("general.error.title"),
                    type: "error",
                };
            },
            successNotification: (data: any, values: any, resource: any) => {

                var messages = getAllMessages(data.data).success;

                var message = `Vukuat kapatıldı`;

                if (messages && messages.length > 0) message = messages[0];
                incidentQueryResult.refetch()
                return {
                    message: message,
                    description: translate("general.success.title"),
                    type: "success",
                };
            }
        })
    }

    const redirectIncident = (description: string, assignedUserID: number) => {
        mutate({
            url: redirectIncidentUrl,
            method: "post",
            values: {
                incidentID: parsedId,
                description,
                assignedUserID
            },
            errorNotification: (data: any, values: any, resource: any) => {
                var messages = getAllMessages(data?.response?.data).error;

                var message = `Something went wrong when changing state ${data.id}`;

                if (messages && messages?.length > 0) message = messages[0];

                redirectForm.resetFields();
                redirectModelClose()
                return {
                    message: message,
                    description: translate("general.error.title"),
                    type: "error",
                };
            },
            successNotification: (data: any, values: any, resource: any) => {

                var messages = getAllMessages(data?.data).success;

                var message = `Vukuat Yönlendirildi`;

                if (messages && messages.length > 0) message = messages[0];
                redirectForm.resetFields();
                redirectModelClose()
                incidentQueryResult.refetch()
                return {
                    message: message,
                    description: translate("general.success.title"),
                    type: "success",
                };
            }
        })
    }

    const answerIncident = (precaution: string, isCompleted: boolean) => {
        mutate({
            url: answerIncidentUrl,
            method: "post",
            values: {
                incidentID: parsedId,
                precaution,
                isCompleted
            },
            errorNotification: (data: any, values: any, resource: any) => {
                var messages = getAllMessages(data?.response?.data).error;

                var message = `Something went wrong when changing state ${data.id}`;

                if (messages && messages?.length > 0) message = messages[0];
                answerForm.resetFields();
                answerModalClose()
                return {
                    message: message,
                    description: translate("general.error.title"),
                    type: "error",
                };
            },
            successNotification: (data: any, values: any, resource: any) => {

                var messages = getAllMessages(data?.data).success;

                var message = `Vukuat Cevaplandı`;

                if (messages && messages?.length > 0) message = messages[0];
                answerForm.resetFields();
                answerModalClose();
                incidentQueryResult.refetch()
                return {
                    message: message,
                    description: translate("general.success.title"),
                    type: "success",
                };
            }
        })
    }

    if (identity && record) {
        if (amIAuthorized) {
            return (
                <>
                    <Modal {...answerModalProps}
                        onCancel={() => {
                            answerModalClose()
                        }}
                        onOk={() => {
                            answerForm.submit();

                        }}
                        confirmLoading={mutateLoading}
                    >
                        <Form onFinish={(values: any) => {
                            answerIncident(values?.precaution, values?.isCompleted)
                        }} form={answerForm}>
                            <Row gutter={[24, 24]}>
                                <Col span={23}>
                                    <Form.Item label="Alınan Aksiyon" name="precaution" rules={[{ required: true }]}>
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                                <Col span={23}>
                                    <Form.Item label="Vukuat Tamamlandı Mı?" name="isCompleted" rules={[{ required: true }]} initialValue={false} hidden={!isAssignedMe}>
                                        <Switch checkedChildren={ConvertBoolean(true)} unCheckedChildren={ConvertBoolean(false)} />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Form>
                    </Modal>
                    <Modal {...redirectModalProps}
                        onCancel={() => {
                            redirectModelClose()
                        }}
                        onOk={() => {
                            redirectForm.submit();
                        }}
                        confirmLoading={mutateLoading}
                    >
                        <Form onFinish={(values: any) => {
                            redirectIncident(values?.description, values?.assignedUserID)
                        }} form={redirectForm}>
                            <Row gutter={[24, 24]}>
                                <Col span={23}>
                                    <Form.Item label="Atanacak Kullanıcı" name="assignedUserID" rules={[{ required: true }]}>
                                        <Select {...userSelectProps} />
                                    </Form.Item>
                                </Col>
                                <Col span={23}>
                                    <Form.Item label="Atama Sebebi" name="description" rules={[{ required: true }]} >
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                    <Show
                        canEdit={false}
                        title={<TagField value={ConvertIncidentStatusToText(record?.incidentStatusID ?? 0)} color={ConvertIncidentStatusToColor(record?.incidentStatusID ?? 0)} />}
                        headerButtons={({ defaultButtons }) => (
                            <>
                                <ListButton
                                    data-testid="edit-list-button"
                                    resource={parsedResource?.name}
                                    children={translate("pages.Incident.title")}
                                />

                                {
                                    canRunAction ?
                                        <>
                                            <RefreshButton
                                                resource={parsedResource?.name}
                                                recordItemId={parsedId}
                                            />
                                            {
                                                (isAssignedMe ?
                                                    <>
                                                        <Button onClick={answerModalShow}>
                                                        {translate("buttons.addsolution")}
                                                        </Button>
                                                        <Button onClick={redirectModalShow}>
                                                        {translate("buttons.redirect")}
                                                        </Button>
                                                    </>
                                                    : <></>)
                                            }
                                            {
                                                (isMyIncident ?
                                                    <Button onClick={handleConfirmation}>
                                                        {translate("buttons.closethecase")}
                                                    </Button> : <></>)
                                            }
                                            <Modal
                visible={showConfirmation}
                title={translate("pages.Incident.fields.modal.title")}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {translate("pages.Incident.fields.modal.cansel")}
                    </Button>,
                    <Button key="confirm" type="primary" onClick={handleConfirm}>
                       {translate("pages.Incident.fields.modal.confirm")}
                    </Button>,
                ]}
            >
                <p>{translate("pages.Incident.fields.modal.question")}</p>
            </Modal>
                                        </> : <></>
                                }
                            </>
                        )}>
                        <Card>
                            <Row gutter={[24, 24]}>
                                <Col span={6}>
                                    <Title level={5}>{translate("pages.Incident.fields.id")}</Title>
                                    <Text>{record?.id}</Text>
                                </Col>
                                <Col span={6}>
                                    <Title level={5}>{translate("pages.Incident.fields.incidentDate")}</Title>
                                    <DateField value={record?.incidentDate} format="LL" />
                                </Col>
                                <Col span={6}>
                                    <Title level={5}>{translate("pages.Incident.fields.responsibleUser")}</Title>
                                    <Text>{record?.responsibleUser?.label}</Text>
                                </Col>
                                <Col span={6}>
                                    <Title level={5}>{translate("pages.Incident.fields.ownerUser")}</Title>
                                    <Text>{record?.ownerUser?.label}</Text>
                                </Col>
                                <Divider />
                                <Col span={6}>
                                    <Title level={5}>{translate("pages.Incident.fields.incidentTypeID")}</Title>
                                    <Text>{record?.incidentType?.name}</Text>
                                </Col>
                                <Col span={6}>
                                    <Title level={5}>{translate("pages.Incident.fields.incidentLocationTypeID")}</Title>
                                    <Text>{record?.incidentLocationType?.name}</Text>
                                </Col>
                                <Col span={6}>
                                    {
                                        record?.incidentLocationTypeID == 1 ?
                                            <>
                                                <Title level={5}>{translate("pages.Incident.fields.departmentID")}</Title>
                                                <Text>{record?.department?.name}</Text>
                                            </> :
                                            <>
                                                <Title level={5}>{translate("pages.Incident.fields.storeID")}</Title>
                                                <Text>{record?.store?.storeName}</Text>
                                            </>
                                    }
                                </Col>
                                <Col span={6}>
                                    <Title level={5}>{translate("pages.Incident.fields.importance")}</Title>
                                    <Text>{record?.importance ?? 1} - {descRate[(record?.importance ?? 1) - 1]}</Text>
                                </Col>
                                <Divider />
                                <Col span={6}>
                                    <Title level={5}>{translate("pages.Incident.fields.incidentCategoryID")}</Title>
                                    <Text>{record?.incidentCategory?.name}</Text>
                                </Col>
                                <Col span={6}>
                                    <Title level={5}>{translate("pages.Incident.fields.incidentCauseID")}</Title>
                                    <Text>{record?.incidentCause?.name}</Text>
                                </Col>
                                <Col span={6}>
                                    <Title level={5}>{translate("pages.Incident.fields.externalTicketID")}</Title>
                                    <Text>{record?.externalTicketID}</Text>
                                </Col>
                                <Col span={6}>
                                    <Title level={5}>{translate("pages.Incident.fields.description")}</Title>
                                    <Text>{record?.description}</Text>
                                </Col>
                            </Row>
                        </Card>
                        <Divider>Süreçler</Divider>
                        <Card>
                            <Table columns={columns} dataSource={record?.actions} />
                        </Card>
                    </Show>
                </>
            );
        }
        else {
            return <ErrorComponent status={403} />
        }
    }
    else {
        return (
            <Row gutter={24}>
                <Col span={24} style={{
                    justifyContent: "center",
                    display: "flex"
                }}>
                    <Spin tip="Page Loading..." size="large" />
                </Col>
            </Row>
        )

    }


}