import {
    useForm,
    Edit,
    useSelect,
    SaveButton,
    DeleteButton,
    ListButton,
    RefreshButton,
    useFileUploadState,
    getValueFromEvent,
} from "@refinedev/antd";

import { Form, Input, Select, Space, Row, Col, Switch, DatePicker, Upload } from "antd";

import {
    ResourceRouterParams,
    useApiUrl,
    useGetIdentity,
    useNavigation,
    useParsed,
    useTranslate,
} from "@refinedev/core";
import { Rate } from 'antd';
import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IUserForUpdate } from "../../../entities/visa/IUserForUpdate";
import { useEffect, useState } from "react";
import { ConvertStatusID } from "helpers/statusHelper";
import React from "react";
import { IRoleResponse } from "entities/visa/IRoleResponse";
import { css } from 'aphrodite'
import sheet from './style';
import { useFormErrorHandler } from "helpers/serviceResponseHelper";
import { useConstantValueFromService } from "hooks";
import IDepartment from "entities/definition/IDepartment";
import IStore from "entities/definition/IStore";
import IIncident from "entities/event/IIncident";
import dayjs from "dayjs";
import IIncidentCategory from "entities/definition/IIncidentCategory";
import IIncidentCause from "entities/definition/IIncidentCause";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IMultiMediaSaveRequest } from "entities/content/IMultiMediaSaveRequest";
import { useGetIdentityInterface } from "models/useGetIdentityInterface";
import { IUserForView } from "entities/visa/IUserForView";


declare type ActionParams = {
    action?: "edit" | "create" | "clone";
};

export const IncidentEdit: React.FC = () => {

    const descRate = ['Düşük', 'Normal', 'Acil'];
    const [rateValue, setRateValue] = useState(1);
    useEffect(() => {
        console.log("Önem Derecesi",rateValue)
      }, [rateValue])
    const apiUrl = useApiUrl();
    const { data: identity } = useGetIdentity<useGetIdentityInterface>();

    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult, form } = useForm<IIncident>(
            {
                redirect: false,
                action: parsedAction == "edit" ? "edit" : "create",
                mutationMode: "undoable",
                errorNotification: useFormErrorHandler
            });
            
    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 1;
    const { goBack, list } = useNavigation();


    const { isLoading, onChange } = useFileUploadState();

    var initialRoles: any[] = [];

    if (mutationResult.isSuccess) {
        redirect("list");
    }

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    const [locationType, setLocationType] = useState(0);
    const onLocationSelectedChange = (value: number) => {
        setLocationType(value)
    }

    //Aksiyon veya bilgilendirme
    const { selectProps: incidentTypeSelectProps } = useConstantValueFromService("GetIncidentTypes")

    //Mağaza veya Departman
    const { selectProps: incidentLocationTypeSelectProps } = useConstantValueFromService("GetIncidentLocationTypes")

    const { selectProps: departmentSelectProps } = useSelect<IDepartment>({
        resource: "Department",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Name",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { selectProps: incidentAssignedSelectProps } = useSelect<IUserForView>({
        resource: "User",
        optionLabel: "label",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Username",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { selectProps: storeSelectProps } = useSelect<IStore>({
        resource: "Store",
        optionLabel: "storeName",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "StoreName",
                operator: "contains",
                value,
            },
            {
                field: "StoreCode",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { selectProps: incidentCauseSelectProps, defaultValueQueryResult: incidentCauseService } = useSelect<IIncidentCause>({
        resource: "IncidentCause",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Name",
                operator: "contains",
                value
            }
        ],
        pagination: {
            mode: "server"
        },
        filters: [
            {
                field: "IncidentCategoryID",
                operator: "eq",
                value: form.getFieldValue("incidentCategoryID")
            }
        ],
        queryOptions: {
            enabled: form.getFieldValue("incidentCategoryID") !== undefined
        }
    });

    const { selectProps: incidentCategorySelectProps } = useSelect<IIncidentCategory>({
        resource: "IncidentCategory",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
        onSearch: (value) => [
            {
                field: "Name",
                operator: "contains",
                value,
            }
        ],
        pagination: {
            mode: "server"
        }
    });

    const { selectProps: userSelectProps } = useSelect<IUserForView>({
        resource: "User",
        optionLabel: "label",
         optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,
         onSearch: (value) => [
             {
                 field: "Username",
                 operator: "contains",
                 value
             }
        ],
         pagination: {
            mode: "server"
        }
});

    const onFileUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file.status == "done") {
            let newMedia: IMultiMediaSaveRequest = {
                mediaID: info.file.response.id,
                uid: info.file.uid
            };
        }

        onChange(info);
    }

    return (
        <Edit
            isLoading={formLoading}
            headerButtons={({ defaultButtons }) => (
                <>
                    <ListButton
                        data-testid="edit-list-button"
                        resource={parsedResource?.name}
                        children={translate("pages.Incident.title")}
                    />
                </>
            )}
            footerButtons={({ defaultButtons }) => (
                <>
                    <SaveButton
                        {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                        }}
                    />
                    {CheckPermissionsV2("Delete", "Business.Concrete.Event.IncidentManager") && parsedId &&
                        <DeleteButton
                            data-testid="edit-delete-button"
                            mutationMode="undoable"
                            onSuccess={() => {
                                redirect("list");
                            }}
                        />
                    }
                </>
            )}
            title={translate("buttons.edit")}
            recordItemId={parsedId}
            canDelete={CheckPermissionsV2("Delete", "IncidentManager")}>

            <Form {...formProps} layout="vertical" ref={formRef}>
                <Row gutter={24}>
                    <Col span={6} key="incidentDate">
                        <Form.Item
                            label={translate("pages.Incident.fields.incidentDate")}
                            name="incidentDate"
                            rules={[{ required: true }]}
                            getValueProps={(value) => ({
                                value: value ? dayjs(value) : "",
                            })}
                            initialValue={dayjs()}
                        >
                            <DatePicker disabled={parsedAction == "create"} />
                        </Form.Item>
                    </Col>
                    <Col span={6} key="importance">
                        <Form.Item label={translate("pages.Incident.fields.importance")} name="importance" rules={[{ required: true }]} initialValue={1}>
                            <span>
                                <Rate count={3} tooltips={descRate} onChange={(value) => {
    setRateValue(value); 
    form.setFieldValue("importance", value); 
}} value={rateValue} />
                                {rateValue ? <span className="ant-rate-text">{descRate[rateValue - 1]}</span> : ''}
                            </span>
                        </Form.Item>
                    </Col>
                    <Col span={12} key="incidentTypeID">
                        <Form.Item label={translate("pages.Incident.fields.incidentTypeID")} name="incidentTypeID" rules={[{ required: true }]}>
                            <Select {...incidentTypeSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="incidentCategoryID">
                        <Form.Item label={translate("pages.Incident.fields.incidentCategoryID")} name="incidentCategoryID" rules={[{ required: true }]}>
                            <Select {...incidentCategorySelectProps} onChange={() => {
                                form.setFieldValue("incidentCauseID", undefined)
                                incidentCauseService.refetch()
                            }} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="incidentCauseID">
                        <Form.Item label={translate("pages.Incident.fields.incidentCauseID")} name="incidentCauseID" rules={[{ required: true }]}>
                            <Select {...incidentCauseSelectProps} disabled={form.getFieldValue("incidentCategoryID") === undefined || incidentCauseService.isRefetching} />
                        </Form.Item>
                    </Col>
                     <Col span={12} key="userID">
                        <Form.Item label={translate("pages.incidentcause.fields.responsibleUser")} name="userID">
                            <Select {...userSelectProps} />
                        </Form.Item>
                    </Col> 
                    <Col span={12} key="incidentLocationTypeID">
                        <Form.Item label={translate("pages.Incident.fields.incidentLocationTypeID")} name="incidentLocationTypeID" rules={[{ required: true }]} initialValue={1}>
                            <Select {...incidentLocationTypeSelectProps} onChange={onLocationSelectedChange} />
                        </Form.Item>
                    </Col>
                    {
                        (locationType == 0 && (userData?.incidentLocationTypeID == 1 || parsedAction == "create")) || locationType == 1 ?
                            (
                                <Col span={12} key="departmentID">
                                    <Form.Item label={translate("pages.Incident.fields.departmentID")} name="departmentID" rules={[{ required: true }]}>
                                        <Select {...departmentSelectProps} />
                                    </Form.Item>
                                </Col>
                            ) :
                            (
                                <Col span={12} key="storeID">
                                    <Form.Item label={translate("pages.Incident.fields.storeID")} name="storeID" rules={[{ required: true }]}>
                                        <Select {...storeSelectProps} />
                                    </Form.Item>
                                </Col>
                            )
                    }
                    <Col span={12} key="description">
                        <Form.Item label={translate("pages.Incident.fields.description")} name="description" rules={[{ required: true }]}>
                            <ReactQuill theme="snow" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="medias"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                        >
                            <Upload.Dragger
                                name="file"
                                action={`${apiUrl}/Media/upload`}
                                listType="text"
                                onChange={onFileUploadChange}
                                headers={
                                    {
                                        "Authorization": `bearer ${identity?.token}`
                                    }
                                }
                                maxCount={5}
                                multiple
                                showUploadList={{
                                    showDownloadIcon: true
                                }}
                            >
                                <p className="ant-upload-text">
                                    {translate("pages.Incident.fields.uploadFile")}
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Edit >
    );
};
