import {
    useForm,
    Edit,
    useSelect,
    SaveButton,
    DeleteButton,
    useCheckboxGroup,
    ListButton,
    RefreshButton,
} from "@refinedev/antd";

import { Form, Input, Select, Space, Row, Col, Switch, Checkbox } from "antd";

import {
    ResourceRouterParams,
    useNavigation,
    useParsed,
    useResourceWithRoute,
    useRouterContext,
    useTranslate,
} from "@refinedev/core";

import { CheckPermissionsV2 } from "../../../helpers/permissionHelper";
import { IForbiddenUsernameRequest } from "../../../entities/visa/IForbiddenUsernameRequest";
import { useState } from "react";
import { ConvertStatusID, ConvertBoolean } from "helpers/statusHelper";
import React from "react";
import ITranslationRequest from "entities/configuration/ITranslationRequest";
import ILanguageResponse from "entities/definition/ILanguageResponse";




export const TranslationEdit: React.FC = () => {
    const {
        resource: parsedResource,
        action: parsedAction,
        id: parsedId,
        pathname: parsedPathname,
        params: parsedParams,
    } = useParsed();

    const [currentLanguageId, setCurrentLanguageId] = useState(1);

    const { formProps, saveButtonProps, queryResult, onFinish,
        redirect, formLoading, mutationResult } = useForm<ITranslationRequest>(
            {
                redirect: false,
                action: parsedAction == "edit" ? "edit" : "create",
                mutationMode: "undoable",
                meta: {
                    variables: {
                        queryStringParams: {
                            languageID: currentLanguageId
                        }
                    }
                }
            }
        );
    const translate = useTranslate();
    const userData = queryResult?.data?.data;
    var initialStatus = userData?.statusID ?? 0;
    const { goBack, list } = useNavigation();

    let formRef = React.useRef<any>(null);

    const onSelectedChange = (checked: boolean) => {
        formRef.current.setFieldsValue({
            statusID: checked ? 1 : 0
        });
    }

    const onLanguageChange = (value: any) => {
        setCurrentLanguageId(value);
        queryResult?.refetch();
    }

    const { selectProps } = useSelect<ILanguageResponse>({
        resource: "Language",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        debounce: 1000,

        onSearch: (value) => [
            {
                field: "name",
                operator: "contains",
                value
            }
        ],

        pagination: {
            mode: "server"
        }
    });

    if (mutationResult.isSuccess) {
        redirect("list");
    }


    return (
        <Edit isLoading={formLoading}
            headerProps={{
                extra: (<Space wrap>
                    <ListButton
                        data-testid="edit-list-button"
                        resource={parsedResource?.name}
                    />
                    {parsedAction == "edit" && (
                        <RefreshButton
                            resource={parsedResource?.name}
                            recordItemId={parsedId}
                        />
                    )}

                </Space>)
            }}
            headerButtons={
                <Space>
                    <SaveButton
                        {...saveButtonProps}
                        onClick={async () => {
                            await onFinish?.();
                        }}
                    />
                    {CheckPermissionsV2("Delete", "Business.Concrete.Configuration.TranslationManager") && parsedId &&
                        <DeleteButton
                            data-testid="edit-delete-button"
                            mutationMode="undoable"
                            onSuccess={() => {
                                redirect("list");
                            }}
                        />
                    }
                </Space>
            }
            title={translate("buttons.edit")} recordItemId={parsedId}>
            <Form {...formProps} layout="vertical" ref={formRef}>
                <Form.Item label={translate("translation.fields.languageId")} name="languageId" hidden>
                    <Input disabled={true} value={currentLanguageId} />
                </Form.Item>
                <Row gutter={24}>
                    
                    <Col span={12} key="key">
                        <Form.Item label={translate("translation.fields.key")} name="key" >
                            <Input disabled={parsedId !== undefined} />
                        </Form.Item>
                    </Col>
                    <Col span={12} key="value">
                        <Form.Item label={translate("translation.fields.value")} name="value" >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    {parsedAction == "edit" ?

                        (<>
                            <Col span={12} key="Language">
                                <Form.Item label={translate("translation.fields.language")} name="languageID">
                                    <Select {...selectProps} onChange={onLanguageChange} />
                                </Form.Item>
                            </Col>
                            <Col span={12} key="Status">
                                <Form.Item label={translate("translation.fields.status")} name="statusID">
                                    <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                                </Form.Item>
                            </Col>
                        </>) :

                        (
                            <Col span={24} key="Status">
                                <Form.Item label={translate("translation.fields.status")} name="statusID">
                                    <Switch checkedChildren={ConvertStatusID(1)} unCheckedChildren={ConvertStatusID(0)} defaultChecked={initialStatus == 1} onChange={onSelectedChange} />
                                </Form.Item>
                            </Col>)}

                </Row>
            </Form>
        </Edit>
    );
};
